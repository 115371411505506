<template>
<v-container>

	<v-row dense class="mt-2">
		<v-snackbar
      v-model="snackbar"
      :timeout="3000"
      top
      color="success"
    >
      {{text}}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

		<v-toolbar dark color="success">
			<v-card-title class="ma-0 py-0">Procesar Pago en Línea
			</v-card-title>
		  <v-spacer></v-spacer>
		<v-btn color="primary" @click="gettoken">Pagar</v-btn>
		  <!-- <v-btn color="primary" @click="saveinfo">Procesar</v-btn> -->
		</v-toolbar>
	</v-row>

	<v-row>
		<v-col cols="6">
			<v-card-text>
			  <div ref="paypal"></div>
			</v-card-text>
		</v-col>

		<v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1">

		  	<v-card class="elevation-2 ma-1 px-1"  >
					<v-card-title class="ma-0 py-0">Datos del Pago</v-card-title>
					<v-card-text>
							<table width="100%">
								<tr>
		              <th style="text-align:left;">IMPORTE: </th>
		              <th style="text-align:right;">{{ getTotales.importe}}</th>
		            </tr>
		            <tr>
		              <th style="text-align:left;">DESCUENTO: </th>
		              <th style="text-align:right;">{{ getTotales.descuento}}</th>
		            </tr>

		            <tr>
		              <th style="text-align:left;">SUBTOTAL: </th>
		              <th style="text-align:right;">{{ getTotales.subtotal}}</th>
		            </tr>
		            <tr>
		              <th style="text-align:left;">IVA: </th>
		              <th style="text-align:right;">{{ getTotales.iva}}</th>
		            </tr>
		            <tr>
		              <th style="text-align:left;">TOTAL: </th>
		              <th style="text-align:right;">{{ getTotales.total}}</th>
		            </tr>
		        </table>
			    </v-card-text>
				</v-card>
		  </v-col>

	</v-row>

	<v-row>
		<v-col cols="12" mmd="6" sm="6" xs="12" class="ma-0 py-1" >
			<v-card class="elevation-2 ma-1 px-1">

				<v-card-title class="ma-0 py-0">Datos Fiscales</v-card-title>

				<v-card-text >
					<div><strong>{{ Clientes.nomcli }}</strong></div>
					<div><strong>{{ Clientes.Rfc }}</strong></div>
					<div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
					<div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
					<div>Atención :{{ Clientes.atvent}}  </div>
						<div> Email :{{Clientes.email1}} </div>
					<div>{{ Clientes.telefono}}</div>
		    </v-card-text>
			</v-card>
	  </v-col>

		<!-- RECOGE EN TIENDA... DIRECCION DE ENVIO -->
	  <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1">
			<v-card class="elevation-2 ma-1 px-1" >

				<v-card-title v-if="Recogeentienda" class="ma-0 py-0">Recoge en Tienda</v-card-title>
				<v-card-title v-else class="ma-0 py-0">Dirección de Envío</v-card-title>

				<v-card-text  class="pa-0 ma-0" v-if="mismaDireccion">
					<div>Atención: {{ Clientes.atvent}}   </div>
					<div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
					<div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
					<div>{{ Clientes.telefono}}</div>
		    </v-card-text>

				<v-card-text class="ma-0 py-0" v-else>
					<div>{{ Direnvio}}</div>
		    </v-card-text>

			</v-card>
	  </v-col>

	</v-row>

		<v-row >

			{{tipopago}}

			<v-col cols="12"  xl="4" lg="4" md="5" sm="5" xs="8">
		  	<v-card class="elevation-5" >
		  	  <v-card-text >
		  	  	<div class="bue--text" >Fecha: {{newFecha}}</div>
		  	    <div class="red--text" >Cotización: {{docant.Numdoc}}</div>
		  	  </v-card-text>
		  	</v-card>
		  </v-col>

			<!-- PAGO POR TRANSFERENCIA -->
		  <v-col cols="12" v-if="tipopago == 3" xl="4" lg="4" md="5" sm="5" xs="8">
		  	<v-card class="elevation-0" outlined>
		  	  <v-card-text>
		  	    <div>Datos para transferencia</div>
		  	    <div>Banco: Banorte</div>
		  	    <div>Cuenta: 2050648097618594689275 </div>
		  	    <div>Clabe: 2050648097618594689275</div>
		  	  </v-card-text>
		  	</v-card>
		  </v-col>

			<!-- LINEA DE CREDITO		 -->
	    <v-col cols="12" v-if="tipopago == 4" xl="4" lg="4" md="5" sm="5" xs="8">
		  	<v-card class="elevation-0" outlined>
		  	  <v-card-text v-if="credito == false">
		  	    <div style="font-size: 18px;"><strong>{{ mensaje }}</strong></div>
		  	  </v-card-text>
		  	  <v-card-text v-else>
		  	    <div style="font-size: 16px;">Días de crédito:  {{ dias }}</div>
		  	    <div style="font-size: 16px;">Saldo disponible: {{ saldo }}</div>
		  	  </v-card-text>
		  	</v-card>
		  </v-col>

		</v-row>

</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data () {
    return {
      tokenSait: '',
      email1: '',
      email2: '',
      Recogeentienda: false,
      tipopago: '',
      mismaDireccion: true,
      Direnvio: '',
      docant: '',
      Clientes: '',
      credito: false,
      mensaje: '',

      direccion: '',
      totales: {},

      fecha: new Date().toISOString().substr(0, 10),
      // fecha:  new Date				comprobanteModal: false,
      saldo: 0,
      dias: 0,
      snackbar: false,
      text: '',
      paidFor: false,
      loadding: false

    }
  },

  mounted () {
    const script = document.createElement('script')
    script.src = 'https://www.paypal.com/sdk/js?client-id=AUHx_ZSy24juvXG0OIcitfDpJYyzzqC9B8LX5SRxjOUlmikDtyQJ6nDvkUfOXRol1Qs4OyK1KK35sHEl'
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },

  created () {
    // console.log("Params",this.$route.params)
    this.tipopago = this.$route.params.tipo
    // console.log(typeof(this.tipopago))

    this.validaCredito()
    this.Iddocum = this.getDocumento.Iddocum
    // console.log("DOCUM", this.Iddocum)

    // this.tipoPago = this.$route.params
    this.Clientes = this.getClientes
    console.log('clientes', this.getClientes)

    // PARA QUE ES ESTA API? por que no se corrige el original.
    	this.$http.get('api/v1/email.list').then(response => {
    		// console.log(response)
      this.email1 = response.body.Email1
      this.email2 = response.body.Email2
    }).catch(err => { console.log(err) })

    this.$http.get('api/v1/order.info/' + this.Iddocum).then(response => {
      this.docant = response.body
      // console.log("Doumento", this.docant)
      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio
      }

      if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc != '') {
        this.Recogeentienda = false
      } else {
        this.Recogeentienda = true
      }
    })

    // LOGIN DE API SAITNUBE
    // var url = 'https://sait-md3.saitnube.com/api/v1/login'
    var Payload = {
      numuser: '1',
      password: '123'
    }

    // API AXIOS SAIT NUBE
    var url = process.env.VUE_APP_SAIT_NUBE
    var uri = url + '/api/v1/login'
    // var url = 'https://sait-md3.saitnube.com/api/v1/login'
  	  console.log(uri)
    console.log('uri', uri, Payload)

    axios.post(uri, Payload, { headers: { 'Content-Type': 'application/json' } }).then(response => {
      // console.log("Sait NUBE",response.data)
      this.tokenSait = response.data.token
      console.log(this.tokenSait)
       	localStorage.setItem('tokensait', this.tokenSait)
    }).catch(error => { console.log(error) })
  },

  methods: {

    setLoaded: function (resp) {
	      this.loaded = true
	      window.paypal
	        .Buttons({
	          createOrder: (data, actions) => {
	            return actions.order.create({
	              purchase_units: [
	                {
	                  description: 'SaitMd3 ' + this.docant.Numdoc,
	                  amount: {
	                    currency: 'MXN',
	                    value: this.getTotales.total
	                  }
	                }
	              ]
	            })
	          },
	          onApprove: async (data, actions, resp) => {
	            this.loadding = true
	            const order = await actions.order.capture()
	            this.data
	            this.paidFor = true
	            this.loadding = false
	            console.log('paypal resp: ', this.resp)
	            window.location.href = './paymentsuccess/' + this.resp
	          },
	          onError: err => {
	            console.log(err)
	          }
	        })

	        .render(this.$refs.paypal)
    },

	 	gettoken () {
	 		console.log('GetToken Papal en BackEnd')
	 	},

    	horaSait () {
      var f = new Date()
      	var hora = f.getHours()
      	var minuto = f.getMinutes()
      	var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    },

	 		enviarCorreo (documento1) {
    		console.log('this.docant', this.docant)

    		var correo = ''
    		if (this.getdatosUsuario.numcli) {
    			correo = this.getdatosUsuario.Email
    		} else {
    			correo = this.CargaCliente.email1
    		}

    		if (this.$store.state.tipomoneda == 'P') {
    			this.moneda = 'MXN'
    		} else {
    			this.moneda = 'USD'
    		}

    		console.log('documento1.numdoc', documento1[0].numdoc)
    		// La respuesta de order.add incluye el iddocum del generado
    		var iddocum = base64.encode(documento1[0].iddocum)
    		// var iddocum = base64.encode(param[0].iddocum);

      	var url = this.$http.options.root.replace('tienda3', 'consultacoti/')

      	this.CargaCliente = this.getClientes
      console.log(this.CargaCliente)

      console.log('correo', correo)

    		var payload = {
    			email: correo,
        subject: 'Pedido Tienda en Linea ',
        info1: 'Pedido',
        folio: documento1[0].numdoc.trim(),
        info3: 'Se ha elaborado un pedido con el folio: ',
        nombre: this.getdatosUsuario.Nomuser,
        direccion: '',
        fecha: this.fecha,

        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,

        rfc: this.CargaCliente.Rfc,

        subtotal: this.getTotales.subtotal,
        iva: this.getTotales.iva,
        total: this.getTotales.total,

        c2a_link: url + iddocum,
        hora: this.horaSait(),
        logo: this.logo,

        color: this.$vuetify.theme.themes.light.secondary,
        email1: this.email1,
        email2: this.email2
    		}

    		console.log(payload)
    		// Enviar el correo
    		this.$http.post('auth/api/v1/sendcot', payload).then(response => {
    			console.log('enviando Correo')
        		// var doc = {doc: iddocum}
        	// this.$router.push({name:'documento', params: {info: doc}})
    		})
    	},

	 		validainfo () {
	 			// AQUI SE DEBEN DE PONER LAS

	 		},

    saveinfo () {
      var me = this

      var documento = this.docant
      documento.status = 9

      var payload = {
        status: 9,
        Tipodoc: this.docant.Tipodoc,
        Numdoc: this.docant.Numdoc
      }
      // console.log(payload)
			  // actualizar el estatus
      this.$http.put('auth/api/v1/updatestatus', payload).then(response => {
        	// console.log(response)
      }).catch(error => { console.log(error) })

      /// ///////////////////////////////////
      // Agregarmos el pedido de esa cotización

      documento.Direnvio = documento.Direnvio
      documento.Tipodoc = ' P'
      documento.Fechapago = this.fecha
      documento.Fecha = this.fecha
      documento.Refer = documento.Numdoc
      documento.status = 0

      var HoraPed = this.horaSait()

      console.log('Documento a convertir a Pedido', documento)

      this.$http.post('api/v1/order.add', documento).then(response => {
        this.text = 'Pedido generado correctamente'
        this.snackbar = true
        console.log('order add', response.body)
				  this.enviarCorreo(response.body)

        setTimeout(function () { me.$router.push({ name: 'cotizaciones' }) }, 3000)
      }).catch(function (error) {
        console.log(error)
      })

 	      // console.log("DOCUMENTO", documento)

      var items = []
    		var i = 1

  			documento.Movim.forEach((element) => {
    			items.push({
	    			tipodoc: ' P',
	    			numdoc: 'A1',
	    			numpar: i,
	    			numart: element.Numart,
	    			desc: 'Manuel',
	    			pend: element.Cant,
	    			pjedesc: element.Pjedesc,
	    			cant: element.Cant,
	    			unidad: element.Unidad,
	    			impuesto1: element.Impuesto1,
	    			impuesto2: 0,
	    			precio: parseFloat(element.Precio),
	    			obs: ''
    			})
    			i = i + 1
    		})

    		// console.log(items)
    		// GRabar pedido en Sait.
    		console.log(documento)
      var Pedidodoc = {
					  tipodoc: documento.Tipodoc,
					  numdoc: 'P55',
					  numuser: '',
					  nunvend: '',

        fecha: documento.Fecha,
					  fentrega: documento.Fecha,
					  fechacapt: documento.Fecha,

					  divisa: documento.divisa,

					  hora: HoraPed,
					  hentrega: '',

					  status: 1,
					  formapago: '1',
					  numalm: documento.Numalm,
					  impuesto1: documento.impuesto1,
					  impuesto2: 0,
					  descuento: documento.descuento,
					  importe: documento.Importe,
					  numcli: documento.Numcli,
					  pjedesc: 0,
					  nomcli: '',
					  direnvio: documento.Direnvio,
					  tc: documento.Tc,
					  obs: documento.Obs,
					  items: items
      }

      console.log('Checar IVA PEDIDO DOC', Pedidodoc)
      console.log('TokenSait', this.tokenSait)

      // API AXIOS SAIT NUBE
  				var url = 'https://sait-md3.saitnube.com/api/v1/pedidos'
				  axios.post(url, Pedidodoc, { headers: { 'Content-Type': 'application/json', 'x-token': this.tokenSait } }).then(response => {
			      console.log('Sait NUBE', response.data)
			    }).catch(error => { console.log(error) })
    },

    validaCredito () {
      if (this.getClientes.diascred == undefined) {
        this.credito = false
        this.mensaje = 'No cuentas con línea de credito'
      } else {
        this.credito = true
        this.saldo = accounting.formatNumber(this.getClientes.saldo, 2)
        this.dias = this.getClientes.diascred
      }
    }

  },

  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),

    newFecha (fecha) {
      return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
    }

  }
}
</script>
